var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"d-block d-lg-flex wrap-card pb-2"},[_c('v-img',{staticClass:"wrap-card__wrap-image",attrs:{"src":_vm.img || require('@/assets/' + 'images/no-img.png' + ''),"lazy-src":require('@/assets/' + 'images/loading-img.gif' + ''),"contain":""}}),_c('div',{staticClass:"d-lg-flex align-center body"},[_c('div',{staticClass:"d-flex flex-column ml-lg-6"},[_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.title)+" ")])]),(_vm.trainingsProgress)?_c('div',{staticClass:"font-weight-bold training-progress justify-space-between hidden-lg-and-up my-2"},[_c('span',{staticClass:"d-inline-block progress mr-12"},[_vm._v("Progress: "+_vm._s(_vm.trainingsProgress.progress))]),_c('span',{staticClass:"d-inline-block notes"},[_vm._v("Note: "+_vm._s(_vm.trainingsProgress.notes ? _vm.trainingsProgress.notes : '- -')+" ")])]):_vm._e(),_c('v-card-text',{staticClass:"description ml-lg-auto p-0"},[_vm._v(" "+_vm._s(_vm.description)+" ")]),(false)?_c('div',{staticClass:"training-progress hidden-md-and-down"},[_c('span',{staticClass:"d-inline-block progress"},[_vm._v("Progress: "+_vm._s(_vm.trainingsProgress.progress))]),_c('span',{staticClass:"d-inline-block notes"},[_vm._v("Note: "+_vm._s(_vm.trainingsProgress.notes ? _vm.trainingsProgress.notes : '- -')+" ")])]):_vm._e(),(_vm.trainingsProgress)?_c('div',{staticClass:"training-progress d-flex d-lg-none justify-center hidden-lg-and-up mt-4"},[_c('v-btn',{attrs:{"large":"","depressed":"","color":"primary","height":"2rem","width":"50%","to":{
              name: 'TrainingsDetail',
              params: { trainingId: _vm.id, eventId: _vm.$route.params.eventId },
            }}},[_vm._v(" "+_vm._s(_vm.$t('meetings.configuration.start'))+" ")])],1):_c('v-card-actions',{staticClass:"start-training-btn hidden-lg-and-down"},[_c('v-btn',{attrs:{"large":"","depressed":"","color":"primary","to":{
              name: 'TrainingsDetail',
              params: { trainingId: _vm.id, eventId: _vm.$route.params.eventId },
            }}},[_vm._v(" "+_vm._s(_vm.$t('trainings.startTrainings'))+" ")])],1),(!_vm.trainingsProgress)?_c('div',{staticClass:"training-progress d-flex d-lg-none justify-center hidden-lg-and-up mt-4"},[_c('v-btn',{attrs:{"large":"","depressed":"","color":"primary","height":"2rem","width":"50%","to":{
              name: 'TrainingsDetail',
              params: { trainingId: _vm.id, eventId: _vm.$route.params.eventId },
            }}},[_vm._v(" "+_vm._s(_vm.$t('meetings.configuration.start'))+" ")])],1):_vm._e()],1),_c('v-fade-transition',[(hover && _vm.trainingsProgress)?_c('v-overlay',{staticClass:"overlay d-none d-lg-flex",attrs:{"absolute":"","color":"#00B5A7"}},[_c('v-btn',{staticClass:"overlay-btn",attrs:{"text":"","to":("/events/" + (_vm.$route.params.eventId) + "/trainings/detail/" + _vm.id)}},[_vm._v(" "+_vm._s(_vm.$t('globals.continue'))+" ")])],1):_vm._e()],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }