<template>
  <v-hover>
    <template v-slot:default="{ hover }">
      <v-card class="d-block d-lg-flex wrap-card pb-2">
        <v-img
          :src="img || require('@/assets/' + 'images/no-img.png' + '')"
          :lazy-src="require('@/assets/' + 'images/loading-img.gif' + '')"
          class="wrap-card__wrap-image"
          contain
        ></v-img>
        <div class="d-lg-flex align-center body">
          <div class="d-flex flex-column ml-lg-6">
            <div class="title">
              {{ title }}
            </div>
            <!--div class="sub-title">Average time: {{ avgTime }}</div-->
          </div>

          <div
            v-if="trainingsProgress"
            class="font-weight-bold training-progress justify-space-between hidden-lg-and-up my-2"
          >
            <span class="d-inline-block progress mr-12"
              >Progress: {{ trainingsProgress.progress }}</span
            >
            <span class="d-inline-block notes"
              >Note:
              {{ trainingsProgress.notes ? trainingsProgress.notes : '- -' }}
            </span>
          </div>
          <v-card-text class="description ml-lg-auto p-0">
            {{ description }}
          </v-card-text>
          <div v-if="false" class="training-progress hidden-md-and-down">
            <span class="d-inline-block progress">Progress: {{ trainingsProgress.progress }}</span>
            <span class="d-inline-block notes"
              >Note:
              {{ trainingsProgress.notes ? trainingsProgress.notes : '- -' }}
            </span>
          </div>
          <div
            v-if="trainingsProgress"
            class="training-progress d-flex d-lg-none justify-center hidden-lg-and-up mt-4"
          >
            <v-btn
              large
              depressed
              color="primary"
              height="2rem"
              width="50%"
              :to="{
                name: 'TrainingsDetail',
                params: { trainingId: id, eventId: $route.params.eventId },
              }"
            >
              {{ $t('meetings.configuration.start') }}
            </v-btn>
          </div>

          <v-card-actions v-else class="start-training-btn hidden-lg-and-down">
            <v-btn
              large
              depressed
              color="primary"
              :to="{
                name: 'TrainingsDetail',
                params: { trainingId: id, eventId: $route.params.eventId },
              }"
            >
              {{ $t('trainings.startTrainings') }}
            </v-btn>
          </v-card-actions>
          <div
            v-if="!trainingsProgress"
            class="training-progress d-flex d-lg-none justify-center hidden-lg-and-up mt-4"
          >
            <v-btn
              large
              depressed
              color="primary"
              height="2rem"
              width="50%"
              :to="{
                name: 'TrainingsDetail',
                params: { trainingId: id, eventId: $route.params.eventId },
              }"
            >
              {{ $t('meetings.configuration.start') }}
            </v-btn>
          </div>
        </div>

        <v-fade-transition>
          <v-overlay
            v-if="hover && trainingsProgress"
            absolute
            color="#00B5A7"
            class="overlay d-none d-lg-flex"
          >
            <v-btn
              class="overlay-btn"
              text
              :to="`/events/${$route.params.eventId}/trainings/detail/${id}`"
            >
              {{ $t('globals.continue') }}
            </v-btn>
          </v-overlay>
        </v-fade-transition>
      </v-card>
    </template>
  </v-hover>
</template>

<script>
export default {
  name: 'TrainingsCard',
  components: {},
  props: {
    img: {
      type: String,
    },
    id: {
      type: String,
    },
    title: {
      type: String,
      required: true,
    },
    avgTime: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    trainingsProgress: {
      type: Object,
      default: null,
    },
  },
  computed: {},
  methods: {
    getRoute(suffix) {
      return `/events/${this.event?.id}/${suffix}`;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/core/variables';

.title {
  font-size: $base-default-font-size !important;
  font-weight: bold;
  width: max-content;
  font-family: $base-font-family !important;
}

.sub-title {
  font-size: $base-default-font-size;
}

.description {
  font-size: $base-default-font-size;
  padding: 0 !important;
}

.body {
  width: 100%;
}

.training-progress {
  .progress {
    font-size: $base-default-font-size !important;
    font-weight: bold;
  }

  .notes {
    font-size: $base-default-font-size !important;
    font-weight: bold;
  }
}

.start-training-btn {
  width: 40%;
  display: flex;
  justify-content: center;
}

.overlay {
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
}

.overlay-btn {
  font-size: $base-xxx-large-font-size;
  font-weight: bold;
}

.wrap-card {
  max-height: unset;
  padding-top: 0.5rem !important;
  padding-right: 0.75rem !important;
  padding-left: 0.75rem !important;
  &__wrap-image {
    @media screen and (max-width: 1023px) {
      border-top-left-radius: unset !important;
      border-top-right-radius: unset !important;
    }
    @media screen and (min-width: 1024px) {
      border-top-right-radius: unset !important;
      border-bottom-right-radius: unset;
      border-top-left-radius: inherit !important;
      border-bottom-left-radius: inherit;
    }
    ::v-deep .v-responsive__sizer {
      padding-bottom: 11rem !important;
    }
    ::v-deep .v-image__image {
      height: 175px;
      background-size: cover;
      @media screen and (min-width: 700px) {
        height: 350px;
      }
    }
    ::v-deep {
      .v-responsive__content {
        height: 175px;
        @media screen and (min-width: 700px) {
          height: 350px;
        }
      }
    }
  }
}

@include breakpoint(medium) {
  .title {
    font-size: $base-default-font-size;
  }
  .description {
    max-width: 320px;
  }
  .training-progress {
    width: 40%;
    .progress {
      width: 30%;
    }

    .notes {
      margin-left: 30%;
      width: 30%;
    }
  }
  .wrap-card {
    max-height: initial;
    padding: 0 !important;
    &__wrap-image {
      ::v-deep .v-responsive__sizer {
        padding-bottom: initial;
      }
      ::v-deep .v-image__image {
        height: 100%;
      }
    }
    &__wrap-image {
      width: 15%;
    }
  }
}
</style>
